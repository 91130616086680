
.feature .container .slick-track{
    height : 500px;
}
.feature .container .slick-slide{
    height:0px;   
}
.feature .container  .imageslide-container{
    height:fit-content;
    width:"fit-content";
    display: none;
    align-items: center;
    align-content: center;
    
    
}
.feature .container  .slick-slider{
    position: relative;
    top: 20px;
}

.feature .container  .slick-slider .slick-dots{
    background-color: transparent !important;
    border-radius: 0% !important;

}
.feature .container  .slick-slider .slick-dots ul{
    background-color: transparent !important;
    padding: 0px !important;
    margin: 0px 0px 30px 0px !important;
    
}
.feature .container  .slick-slider .slick-arrow{
    display:none !important;
}
.feature .container  .slick-slider .slick-dots ul li{
   background-color: #fff;
   border-radius: 25px;
}
.carousel-container {
    max-width: 100%;
    margin: 0 auto;
    background-color: transparent;
  }

.custom-arrows-container {
  display: flex;
  position: relative;
  align-items: center;
  bottom: 200px;
  right:20px;
  width:107%;
  justify-content: space-between;
}

.custom-arrows-container button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.custom-arrows-container img {
  width: 50px; /* Adjust the width as needed */
  height: auto; /* To maintain aspect ratio */
}
/* Inside your ImageSwiper.scss or in your component's style */
.slick-prev,
.slick-next {
  display: none;
}

@media(max-width: 991px){
    
}